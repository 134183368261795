export const columns = [
{
    title: 'id',
    dataIndex: 'id',
    key: 'id',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '部门名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '是否启用',
    dataIndex: 'flagEnable',
    key: 'flagEnable',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '创建人',
    dataIndex: 'createUser',
    key: 'createUser',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '创建人id',
    dataIndex: 'createUserId',
    key: 'createUserId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '修改人',
    dataIndex: 'modifyUser',
    key: 'modifyUser',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '修改人id',
    dataIndex: 'modifyUserId',
    key: 'modifyUserId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '修改时间',
    dataIndex: 'modifyTime',
    key: 'modifyTime',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '是否删除',
    dataIndex: 'flagDel',
    key: 'flagDel',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '版本号',
    dataIndex: 'version',
    key: 'version',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '父id',
    dataIndex: 'parentId',
    key: 'parentId',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
]
